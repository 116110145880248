import { Link, useNavigate } from 'react-router-dom';
import { useEffect, useState } from 'react';
import HeadTags from 'shared/HeadTags';
import ym from 'react-yandex-metrika';
import { useGetCategoryQuery, useGetServicesListQuery } from 'services/api/api';
import LoadingBlock from 'shared/LoadingBlock';
import Breadcrumbs from 'shared/Breadcrumbs';
import { ServicesMap } from 'services/api/types/DataTypes';

const PriceBlock = ({ name, children }: { name: string, children: JSX.Element }) => {
    const [open, setOpen] = useState(false);
    return (
        <div className={`price-block ${open ? 'is-active' : ''}`}>
            <span className="price-toggle price-block_header" onClick={() => setOpen(state => !state)}>{name}</span>
            <div className="price-block_hidden">{children}</div>
        </div>
    )
};

const PricePage = ({openPopup}: {openPopup?: (state: any) => void}) => {
    const navigate = useNavigate();
    const { data, isLoading } = useGetServicesListQuery();
    const { data: categoryData, isLoading: categoryLoading } = useGetCategoryQuery();

     // const categoryWithParent = (data && categoryData) ? data.filter(category => category.parentCategoryId != null) : [];
    // const categoryWithoutParent = (data && categoryData) ? data.filter(category => category.parentCategoryId == null) : [];
    // let categoriesTree: {
    //     id: number,
    //     name: string,
    //     category: ServicesMap[]
    // }[] = [];
    // const createMap = (el: ServicesMap) => {
    //     const parentCategory = categoriesTree.find(item => item.id === el.parentCategoryId);
    //     if (parentCategory) {
    //         parentCategory.category.push(el);
    //     } else {
    //         categoriesTree.push({
    //             id: el.parentCategoryId,
    //             name: categoryData.categoriesMap[el.parentCategoryId].name,
    //             category: [el]
    //         });
    //     }
    // };
    // categoryWithParent.forEach(el => createMap(el));

    useEffect(() => {
        ym('hit', '/ceny');
        window.scrollTo({
            top: 0,
            behavior: "smooth"
        });
    }, [navigate]);

    return (
        <div className="prices">
            <HeadTags
                title='Цены на услуги салона красоты "Граф Ягода"'
                metaDescription='Цены на все услуги нашего салона. Приходите в наш салон красоты Граф Ягода и получите удовольствие от наших услуг.'
            />
            <div className="container">
                <LoadingBlock isLoading={isLoading && categoryLoading}>
                        {categoryData
                        ? categoryData.categories.length
                            ? <>
                                <Breadcrumbs pageName={'Цены'}/>
                                <h1>Цены на услуги салона</h1>
                                {categoryData.categories.map((category, i) => {
                                    if (category.services.length) {
                                        return (
                                            <section className="prices_section">                                                
                                                {<h2>{category.name}</h2>}
                                                <ul className="price-block_list">
                                                    {category.services.length && category.services.map(service => <li key={service.id}>
                                                        <span>{service.name}</span>
                                                        <b>{service.price} {service.max_price && `- ${service.max_price}`} ₽</b>
                                                        <button className="entry-btn entry-btn--beige" onClick={() => openPopup(null)}>Записаться</button>
                                                    </li>)}
                                                </ul>
                                            </section>
                                        );
                                    } else if (category.children.length && category.children.some(category => category.services.length)) {
                                        return (
                                            <section className="prices_section">
                                                {i === 0 ? <h1>{category.name}</h1> : <h2>{category.name}</h2>}
                                                {category.children.map(childCategory => {
                                                    if (childCategory.services.length) {
                                                        return (
                                                            <PriceBlock name={childCategory.name} key={`category_${childCategory.id}`}>
                                                                <ul className="price-block_list">
                                                                    {childCategory.services.length && childCategory.services.map(service => <li key={service.id}>
                                                                        <span>{service.name}</span>
                                                                        <b>{service.price} {service.max_price && `- ${service.max_price}`} ₽</b>
                                                                        <button className="entry-btn entry-btn--beige" onClick={() => openPopup(null)}>Записаться</button>
                                                                    </li>)}
                                                                </ul>
                                                            </PriceBlock>
                                                        );
                                                    } else return '';
                                                })}
                                            </section>
                                        );
                                    } else return '';
                                })}
                            </>
                            : null
                        : <p>Загрузка ...</p>}
                </LoadingBlock>
            </div>
        </div>
    );
};
export default PricePage;