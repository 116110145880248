import { Swiper, SwiperSlide } from "swiper/react";
import { Navigation, Pagination } from "swiper";
import { GalleryImg } from "services/api/types/DataTypes";

const ServiceGallery = ({slides, name} : {slides: GalleryImg[], name:string}) => {
  return (    
    <div className="service__slider container">        
        <Swiper
            className="service-slider"
            modules={[Navigation, Pagination]}
            spaceBetween={10}
            slidesPerView={1}
            pagination
            navigation={{
            nextEl: "#service-next",
            prevEl: "#service-prev",
            }}
            breakpoints={{
            "400": {
                slidesPerView: 2,
                spaceBetween: 10,
            },
            "768": {
                slidesPerView: 3,
                spaceBetween: 15,
            },
            "1140": {
                slidesPerView: 4,
                spaceBetween: 20,
            },
            }}
            loop
            lazy
        >
            {slides.map((slide, i) => {
            return (
                <SwiperSlide key={i}>
                <div>
                    <img
                    alt={`${name} - фото ${i}`}
                    src={slide.image}
                    title={`${name} - фото ${i}`}
                    />
                </div>
                </SwiperSlide>
            );
            })}
        </Swiper>
        <div className="slider-prev" id="service-prev"></div>
        <div className="slider-next" id="service-next"></div>
    </div>
  );
};

export default ServiceGallery;
