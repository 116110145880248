import { useNavigate } from 'react-router-dom';
import { useEffect } from 'react';
import HeadTags from 'shared/HeadTags';
import ym from 'react-yandex-metrika';
import { useGetHairdressPageTextQuery } from 'services/api/api';
import LoadingBlock from 'shared/LoadingBlock';
import Breadcrumbs from 'shared/Breadcrumbs';
import ServiceGallery from 'shared/ServiceGallery';
import parse from 'html-react-parser';
import './hairdress.style.scss';

const HairdressPage = () => {
    const navigate = useNavigate();
    const { data, isLoading, isError } = useGetHairdressPageTextQuery();

    useEffect(() => {
        ym('hit', '/uslugi/parikmaherskie-uslugi');
        window.scrollTo({
            top: 0,
            behavior: "smooth"
        });
    }, [navigate]);

    return (
        <div className="hairdress service-page">
            <LoadingBlock isLoading={isLoading}>
                {isError
                    ? <p>Ошибка получения данных</p>
                    : <>
                        <HeadTags
                            title={data && data.seo?.title ? data.seo.title : 'Парикмахерские услуги и уход за волосами'}
                            metaDescription={data && data.seo?.description ? data.seo.description : 'Парикмахерские услуги и уход за волосами'}
                        />
                        <div className="hairdress service-page">
                            <div className="container">
                                <Breadcrumbs pageName={data?.title} isService = {true}/>
                                <h1>{data?.title}</h1>
                            </div>
                            <section className="hairdress_intro">
                                <div className="container">
                                    <div className="hairdress_intro__row row">
                                        <div className="hairdress_intro__text">
                                            <div className="hairdress_intro__billet billet">
                                                <p className="paragraph-decor">{data?.first_block_text && parse(data?.first_block_text)}</p>
                                            </div>
                                            <div className="hairdress_intro__block">
                                                <ul className="hairdress_intro__list">
                                                    <li>{data?.first_block_list_first_item};</li>
                                                    <li>{data?.first_block_list_second_item}</li>
                                                    <li>{data?.first_block_list_third_item}</li>
                                                    <li>{data?.first_block_list_fourth_item}</li>
                                                    <li>{data?.first_block_list_fifth_item}</li>
                                                    <li>{data?.first_block_list_sixth_item}</li>
                                                </ul>
                                            </div>
                                        </div>
                                        {data?.first_block_photo && <div className="hairdress_intro__img">
                                            <img src={data?.first_block_photo} alt="Парикмахерские услуги" title="Парикмахерские услуги"/>
                                        </div>}
                                    </div>
                                </div>
                            </section>
                            <section className="hairdress_spacer">
                                <div className="container">
                                    <div className="hairdress_spacer__block">
                                        <div className="paragraph-decor">{data?.second_block_text && parse(data?.second_block_text)}</div>
                                    </div>
                                </div>
                            </section>
                            <section className="hairdress_color">
                                <div className="container">
                                    <div className="hairdress_color__row row">
                                        <div className="hairdress_color__text">
                                            <h2>{data?.third_block_title}</h2>
                                            <div className="paragraph-decor">{data?.third_block_text && parse(data?.third_block_text)}</div>
                                        </div>
                                        {data?.third_block_photo && <div className="hairdress_color__img">
                                            <img src={data?.third_block_photo} alt="Цветное окрашивание" title="Цветное окрашивание"/>
                                        </div>}
                                    </div>
                                </div>
                            </section>
                        </div>
                        {
                            data?.gallery_images.length ? ( 
                                <div className="service_gallery">
                                    <h2>Фото процесса и результат</h2>                                                                   
                                    <ServiceGallery slides ={data.gallery_images} name={data?.title} />   
                                </div>                             
                            ) : null
                        }
                    </>
                }
            </LoadingBlock>
        </div >
    );
};
export default HairdressPage;