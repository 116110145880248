import { Link, useNavigate } from 'react-router-dom';
import { useEffect } from 'react';
import HeadTags from 'shared/HeadTags';
import ym from 'react-yandex-metrika';
import { useGetEmcPageTextQuery } from 'services/api/api';
import LoadingBlock from 'shared/LoadingBlock';
import Breadcrumbs from 'shared/Breadcrumbs';
import ServiceGallery from 'shared/ServiceGallery';
import parse from 'html-react-parser';
import './emc.style.scss';

const EmcPage = () => {
    const navigate = useNavigate();
    const { data, isLoading, isError } = useGetEmcPageTextQuery();

    useEffect(() => {
        ym('hit', '/uslugi/trenirovki-ems');
        window.scrollTo({
            top: 0,
            behavior: "smooth"
        });
    }, [navigate]);

    return (
        <div className="ems">
            <LoadingBlock isLoading={isLoading}>
                {isError
                    ? <p>Ошибка получения данных</p>
                    : <>
                        <HeadTags
                            title={data && data.seo?.title ? data.seo.title : 'EMS тренировки в Брянске'}
                            metaDescription={data && data.seo?.description ? data.seo.description : 'EMS тренировки в Брянске'}
                        />
                        <div className="container">
                            <Breadcrumbs pageName={data?.title} isService = {true}/>
                            <h1>{data?.title}</h1>
                            <p className="ems_paragraph">{data?.first_paragraph && parse(data?.first_paragraph)}</p>
                            <ul className="ems_list">
                                <li>
                                    {data?.first_block_first_photo && <img src={data?.first_block_first_photo} alt="Укрепление мышц" title="Укрепление мышц" />}
                                    <div className="ems_list__block">
                                        <h2 className="ems_list__sub">{data?.first_block_first_title}</h2>
                                        <p className="paragraph-decor">{data?.first_block_first_text && parse(data?.first_block_first_text)}</p>
                                    </div>
                                </li>
                                <li>
                                    {data?.first_block_second_photo && <img src={data?.first_block_second_photo} alt="Формирование рельефного тела" title="Формирование рельефного тела" />}
                                    <div className="ems_list__block ems_list__block--bg">
                                        <h2 className="ems_list__sub">{data?.first_block_second_title}</h2>
                                        <p className="paragraph-decor">{data?.first_block_second_text && parse(data?.first_block_second_text)}</p>
                                    </div>
                                </li>
                            </ul>
                        </div>
                        <div className="ems_dark">
                            <div className="container">
                                <ul className="ems_list">
                                    <li>
                                        <div className="ems_list__block">
                                            <h2 className="ems_list__sub">{data?.second_block_first_title}</h2>
                                            <p className="paragraph-decor">{data?.second_block_first_text && parse(data?.second_block_first_text)}</p>
                                        </div>
                                    </li>
                                    <li>
                                        <div className="ems_list__block">
                                            <h2 className="ems_list__sub">{data?.second_block_second_title}</h2>
                                            <p className="paragraph-decor">{data?.second_block_second_text && parse(data?.second_block_second_text)}</p>
                                        </div>
                                    </li>
                                </ul>
                            </div>
                        </div>
                        <div className="container">
                            <ul className="ems_list">
                                <li>
                                    {data?.third_block_first_photo && <img src={data?.third_block_first_photo} alt="Снижение веса" title="Снижение веса" />}
                                    <div className="ems_list__block ems_list__block--bg">
                                        <h2 className="ems_list__sub">{data?.third_block_first_title}</h2>
                                        <p className="paragraph-decor">{data?.third_block_first_text && parse(data?.third_block_first_text)}</p>
                                    </div>
                                </li>
                                <li>
                                    {data?.third_block_second_photo && <img src={data?.third_block_second_photo} alt="Быстрое восстановление  после родов" title="Быстрое восстановление  после родов" />}
                                    <div className="ems_list__block ">
                                        <h2 className="ems_list__sub">{data?.third_block_second_title}</h2>
                                        <p className="paragraph-decor">{data?.third_block_second_text && parse(data?.third_block_second_text)}</p>
                                    </div>
                                </li>
                            </ul>
                        </div>
                        <div className="ems_gray">
                            <div className="container">
                                <div className="ems_row">
                                    <div className="ems_row__img">
                                        {data?.fourth_block_photo && <img src={data?.fourth_block_photo} alt="Избавление от болей в спине" title="Избавление от болей в спине" />}
                                    </div>
                                    <div className="ems_row__text">
                                        <h2 className="ems_list__sub">{data?.fourth_block_title}</h2>
                                        <p className="paragraph-decor">{data?.fourth_block_text && parse(data?.fourth_block_text)}</p>
                                    </div>
                                </div>
                            </div>
                        </div>
                        {
                            data?.gallery_images.length ? ( 
                                <div className="service_gallery">
                                    <h2>Фото процесса и результат</h2>                                                                   
                                    <ServiceGallery slides ={data.gallery_images} name={data?.title} />   
                                </div>                             
                            ) : null
                        }
                    </>
                }
            </LoadingBlock>
        </div >
    );
};
export default EmcPage;