import { useCreateFormRequestMutation } from "services/api/api";
import { FormRequest } from "services/api/types/FormRequest";
import { Formik, Form, Field, ErrorMessage } from 'formik';
import * as yup from 'yup';
import MaskedInput from "react-text-mask";
import { useState } from 'react';
import ModalPopup from "shared/ModalPopup";
import FormResult from "shared/FormResult";

const QuestionForm = ({setShow, personId}: {setShow: (boolean) => void, personId: any}) => {
    const [createFormRequest] = useCreateFormRequestMutation();
    const phoneNumberMask = [
        "+",
        "7",
        "(",
        /\d/,
        /\d/,
        /\d/,
        ")",
        /\d/,
        /\d/,
        /\d/,
        "-",
        /\d/,
        /\d/,
        "-",
        /\d/,
        /\d/
    ];
    const validationsSchema = yup.object().shape({
        name: yup.string()
            .min(3, 'Слишком короткое имя')
            .matches(
                /[а-я,А-Я]/,
                'Имя должно содержать только русские буквы'
            )
            .matches(/^([^0-9]*)$/gm, "'Имя должно содержать только буквы")
            .required('Обязательно к заполнению'),
        phone: yup.string()
            .required('Обязательно к заполнению')
            .transform(value => value.replace(/[^\d]/g, ''))
            .min(11, "Введите корректный номер"),
        comment: yup.string()
            .test(
                'nourl',
                'Текст сообщения введен некорректно',
                (value) => {
                    return !value.match(/(www\.)|(https?:)|(\.ru)|(\.com)|(\.рф)/)
                }
            )
            .required('Обязательно к заполнению'),
    });
    const [resultShow, setResultShow] = useState(false);
    const [resultText, setResultText] = useState({
        status: '',
        text: ''
    });
    const closeAllPopup = (state: boolean) => {
        setResultShow(state);
        setShow(state);
    };
    return (
        <>
            <h2>Записаться в салон</h2>
            <Formik
                initialValues={{
                    name: '',
                    phone: '',
                    comment: '',
                    employee: personId
                }}
                validateOnBlur
                onSubmit={async ({ name, phone, comment, employee}, {resetForm}) => {
                    const body: FormRequest = {
                        name,
                        phone,
                        comment,
                        employee,
                        form_source_url: `${window.location}`
                    };
                    let res = await createFormRequest({ body });
                    if (res['error']) {
                        setResultShow(true);
                        setResultText({
                            status: 'Ошибка!',
                            text: `Попробуйте позже`
                        });
                    } else {
                        resetForm();
                        setResultShow(true);
                        setResultText({
                            status: 'Спасибо!',
                            text: `Ваша заявка отправлена`
                        });
                    }
                }}
                validationSchema={validationsSchema}
            >
                {({
                    handleSubmit,
                    isSubmitting
                }) => (
                    <Form className="popup-form entry-form" onSubmit={handleSubmit}>
                        <div className="form-field">
                            <Field
                                type="text"
                                name="name"
                                placeholder="ФИО"
                                className="form-entry" />
                            <ErrorMessage name="name" component="span" className="form-error" />
                        </div>
                        <div className="form-field">
                            <Field name="phone">
                                {({ field }) => <MaskedInput
                                    {...field}
                                    mask={phoneNumberMask}
                                    placeholder="+7 (___) __ - __"
                                    type="tel"
                                    className="form-entry"
                                />
                                }
                            </Field>
                            <ErrorMessage name="phone" component="span" className="form-error" />
                        </div>
                        <div className="form-field">
                            <Field
                                as="textarea"
                                placeholder="Комментарий"
                                name="comment"
                                className="form-entry" />
                            <ErrorMessage name="comment" component="span" className="form-error" />
                        </div>
                        <Field type="hidden" name="employee" />
                        <div className="popup_controls">
                            <p className="popup_agreement">Отправляя данную форму, даю согласие на <a href="#" target="_blank">обработку персональных данных</a></p>
                            <button className="popup_btn" type="submit" disabled={isSubmitting} >Отправить</button>
                        </div>
                    </Form>
                )}
            </Formik>
            {resultShow && <ModalPopup setShow={resultText.status === 'Ошибка!' ? setResultShow : closeAllPopup} addedClass="form-result">
                <FormResult status={resultText.status} text={resultText.text}/>
            </ModalPopup>}
        </>
    );
};
export default QuestionForm;
