import { Link, useNavigate } from 'react-router-dom';
import { useEffect } from 'react';
import HeadTags from 'shared/HeadTags';
import ym from 'react-yandex-metrika';
import { useGetVisagePageTextQuery } from 'services/api/api';
import LoadingBlock from 'shared/LoadingBlock';
import Breadcrumbs from 'shared/Breadcrumbs';
import ServiceGallery from 'shared/ServiceGallery';
import parse from 'html-react-parser';
import './visage.style.scss';

const VisagePage = () => {
    const navigate = useNavigate();
    const { data, isLoading, isError } = useGetVisagePageTextQuery();

    useEffect(() => {
        ym('hit', '/uslugi/visage');
        window.scrollTo({
            top: 0,
            behavior: "smooth"
        });
    }, [navigate]);

    return (
        <div className="vizazh">
            <LoadingBlock isLoading={isLoading}>
                {isError
                    ? <p>Ошибка получения данных</p>
                    : <>
                        <HeadTags
                            title={data && data.seo?.title ? data.seo.title : 'Архитектура бровей – популярная процедура в нашем салоне'}
                            metaDescription={data && data.seo?.description ? data.seo.description : 'Архитектура бровей – популярная процедура в нашем салоне'}
                        />
                        <div className="container">
                            <Breadcrumbs pageName={data?.title} isService = {true}/>
                            <h1>{data?.title}</h1>
                        </div>
                        <section className="vizazh_intro">
                            <div className="container">
                                <div className="vizazh_intro__row row">
                                    {data?.first_block_photo && <div className="vizazh_intro__img">
                                        <img src={data?.first_block_photo} alt="Архитектура бровей" title="Архитектура бровей" />
                                    </div>}
                                    <div className="vizazh_intro__text">
                                        <p className="paragraph-decor">{data?.first_block_main_text && parse(data?.first_block_main_text)}</p>
                                        <p className="vizazh_intro__sub">{data?.first_block_sub_title}</p>
                                        <ul className="vizazh_intro__list">
                                            <li>
                                                <b>{data?.first_block_list_first_b}</b>
                                                <p>{data?.first_block_list_first_p}</p>
                                            </li>
                                            <li>
                                                <b>{data?.first_block_list_second_b}</b>
                                                <p>{data?.first_block_list_second_p}</p>
                                            </li>
                                            <li>
                                                <b>{data?.first_block_list_third_b}</b>
                                                <p>{data?.first_block_list_third_p}</p>
                                            </li>
                                            <li>
                                                <b>{data?.first_block_list_fourth_b}</b>
                                                <p>{data?.first_block_list_fourth_p}</p>
                                            </li>
                                            <li>
                                                <b>{data?.first_block_list_fifth_b}</b>
                                                <p>{data?.first_block_list_fifth_p}</p>
                                            </li>
                                        </ul>
                                    </div>
                                </div>
                            </div>
                        </section>
                        <section className="vizazh_permanent">
                            <div className="container">
                                <div className="vizazh_permanent__row row">
                                    <div className="vizazh_permanent__text">
                                        <h2>{data?.second_block_title}</h2>
                                        <p className="paragraph-decor">{data?.second_block_text && parse(data?.second_block_text)}</p>
                                    </div>
                                    {data?.second_block_photo && <div className="vizazh_permanent__img">
                                        <img src={data?.second_block_photo} alt="Перманентный макияж" title="Перманентный макияж" />
                                    </div>}
                                </div>
                            </div>
                        </section>
                        <section className="vizazh_preffs__wrap">
                            <div className="container">
                                <h2>{data?.third_block_title}</h2>
                            </div>
                            <div className="vizazh_preffs">
                                <div className="container">
                                    <ul className="vizazh_preffs__list">
                                        <li>
                                            <h3>{data?.third_block_first_sub_title}</h3>
                                            {data?.third_block_first_text && parse(data?.third_block_first_text)}
                                        </li>
                                        <li>
                                            <h3>{data?.third_block_second_sub_title}</h3>
                                            {data?.third_block_second_text && parse(data?.third_block_second_text)}
                                        </li>
                                        <li>
                                            <h3>{data?.third_block_third_sub_title}</h3>
                                            {data?.third_block_third_text && parse(data?.third_block_third_text)}
                                        </li>
                                        <li>
                                            <h3>{data?.third_block_fourth_sub_title}</h3>
                                            {data?.third_block_fourth_text && parse(data?.third_block_fourth_text)}</li>
                                        <li>
                                            <h3>{data?.third_block_fifth_sub_title}</h3>
                                            {data?.third_block_fifth_text && parse(data?.third_block_fifth_text)}
                                        </li>
                                    </ul>
                                </div>
                            </div>
                        </section>
                        <section className="vizazh_holiday">
                            <div className="container">
                                <h2>{data?.fourth_block_title}</h2>
                            </div>
                            <div className="vizazh_holiday__row row container">
                                <div className="vizazh_holiday__text">
                                    <div className="vizazh_holiday__billet">
                                        <p className="paragraph-decor">{data?.fourth_block_main_text && parse(data?.fourth_block_main_text)}</p>
                                    </div>
                                    <p className="vizazh_holiday__sub">{data?.fourth_block_sub_title}</p>
                                    <ul className="vizazh_holiday__list">
                                        <li>
                                            <b>{data?.fourth_block_list_first_b}</b>
                                            {data?.fourth_block_list_first_p && parse(data?.fourth_block_list_first_p)}
                                        </li>
                                        <li>
                                            <b>{data?.fourth_block_list_second_b}</b>
                                            {data?.fourth_block_list_second_p && parse(data?.fourth_block_list_second_p)}
                                        </li>
                                        <li>
                                            <b>{data?.fourth_block_list_third_b}</b>
                                            {data?.fourth_block_list_third_p && parse(data?.fourth_block_list_third_p)}
                                        </li>
                                        <li>
                                            <b>{data?.fourth_block_list_fourth_b}</b>
                                            {data?.fourth_block_list_fourth_p && parse(data?.fourth_block_list_fourth_p)}
                                        </li>
                                        <li>
                                            <b>{data?.fourth_block_list_fifth_b}</b>
                                            {data?.fourth_block_list_fifth_p && parse(data?.fourth_block_list_fifth_p)}
                                        </li>
                                    </ul>
                                </div>
                                {data?.fourth_block_photo && <div className="vizazh_holiday__img">
                                    <img src={data?.fourth_block_photo} alt="Праздничный макияж" title="Праздничный макияж" />
                                </div>}
                            </div>
                        </section>
                        <section className="vizazh_wedding">
                            <div className="container">
                                <h2>{data?.fifth_block_title}</h2>
                                <div className="vizazh_wedding__row row">
                                    {data?.fifth_block_photo && <div className="vizazh_wedding__img">
                                        <img src={data?.fifth_block_photo} alt="Мейкап на свадьбу" title="Мейкап на свадьбу" />
                                    </div>}
                                    <div className="vizazh_wedding__text">
                                        <ul className="vizazh_wedding__list">
                                            <li>
                                                <h3>{data?.fifth_block_first_sub_title}</h3>
                                                {data?.fifth_block_first_text && parse(data?.fifth_block_first_text)}
                                            </li>
                                            <li>
                                                <h3>{data?.fifth_block_second_sub_title}</h3>
                                                {data?.fifth_block_second_text && parse(data?.fifth_block_second_text)}
                                            </li>
                                            <li>
                                                <h3>{data?.fifth_block_third_sub_title}</h3>
                                                {data?.fifth_block_third_text && parse(data?.fifth_block_third_text)}
                                            </li>
                                            <li>
                                                <h3>{data?.fifth_block_fourth_sub_title}</h3>
                                                {data?.fifth_block_fourth_text && parse(data?.fifth_block_fourth_text)}
                                            </li>
                                            <li>
                                                <h3>{data?.fifth_block_fifth_sub_title}</h3>
                                                {data?.fifth_block_fifth_text && parse(data?.fifth_block_fifth_text)}
                                            </li>
                                        </ul>
                                    </div>
                                </div>
                            </div>
                        </section>
                        {
                            data?.gallery_images.length ? ( 
                                <div className="service_gallery">
                                    <h2>Фото процесса и результат</h2>                                                                   
                                    <ServiceGallery slides ={data.gallery_images} name={data?.title} />   
                                </div>                             
                            ) : null
                        }
                    </>
                }
            </LoadingBlock>
        </div >
    );
};
export default VisagePage;