import { Link, useNavigate } from 'react-router-dom';
import { useEffect } from 'react';
import HeadTags from 'shared/HeadTags';
import ym from 'react-yandex-metrika';
import { useGetSharkoPageTextQuery } from 'services/api/api';
import LoadingBlock from 'shared/LoadingBlock';
import Breadcrumbs from 'shared/Breadcrumbs';
import ServiceGallery from 'shared/ServiceGallery';
import parse from 'html-react-parser';
import './sharko.style.scss';

const SharkoPage = () => {
    const navigate = useNavigate();
    const { data, isLoading, isError } = useGetSharkoPageTextQuery();

    useEffect(() => {
        ym('hit', '/uslugi/dush-sharko');
        window.scrollTo({
            top: 0,
            behavior: "smooth"
        });
    }, [navigate]);

    return (
        <div className="sharko">
            <LoadingBlock isLoading={isLoading}>
                {isError
                    ? <p>Ошибка получения данных</p>
                    : <>
                        <HeadTags
                            title={data && data.seo?.title ? data.seo.title : 'Душ Шарко'}
                            metaDescription={data && data.seo?.description ? data.seo.description : 'Душ Шарко'}
                        />
                        <div className="container">
                            <Breadcrumbs pageName={data?.title} isService = {true}/>
                            <h1>{data?.title}</h1>
                        </div>
                        <div className="sharko_intro">
                            <div className="container sharko_intro__row">
                                {data?.first_block_photo && <div className="sharko_intro__img">
                                    <img src={data?.first_block_photo} alt="Душ Шарко - фото 1" title="Душ Шарко" />
                                </div>}
                                <div className="sharko_intro__content">
                                    <p className="paragraph-decor">{data?.first_block_text && parse(data?.first_block_text)}</p>
                                    <ul className="sharko_intro__list">
                                        <li>детоксикация</li>
                                        <li>повышение иммунитета</li>
                                        <li>обезболивающий эффект</li>
                                        <li>уменьшение воспаления</li>
                                        <li>оптимизация снабжения<br/> организма кислородом</li>
                                        <li>активизация метаболизма</li>
                                        <li>стимуляция<br/> гипоталамо-гипофизарной системы, благодаря чему улучшается работа эндокринной
                                            системы</li>
                                    </ul>
                                </div>
                            </div>
                        </div>
                        <div className="container">
                            <div className="sharko_imgs">
                                {data?.second_block_first_photo && <img src={data?.second_block_first_photo} alt="Душ Шарко - фото 2" title="Душ Шарко" />}
                                {data?.second_block_second_photo && <img src={data?.second_block_second_photo} alt="Душ Шарко - фото 3" title="Душ Шарко" />}
                                {data?.second_block_third_photo && <img src={data?.second_block_third_photo} alt="Душ Шарко - фото 4" title="Душ Шарко" />}
                            </div>
                        </div>
                        <div className="sharko_outro">
                            <div className="container">
                                <div className="sharko_outro__row">
                                    <div className="sharko_outro__text">
                                        <div className="sharko_outro__billet">
                                            <p className="paragraph-decor">{data?.third_block_first_text && parse(data?.third_block_first_text)}</p>
                                        </div>
                                        {data?.third_block_second_text && parse(data?.third_block_second_text)}
                                    </div>
                                    {data?.third_block_photo && <div className="sharko_outro__img">
                                        <img src={data?.third_block_photo} alt="Душ Шарко - фото 5" title="Душ Шарко" />
                                    </div>}
                                </div>
                            </div>
                        </div>
                        {
                            data?.gallery_images.length ? ( 
                                <div className="service_gallery">
                                    <h2>Фото процесса и результат</h2>                                                                   
                                    <ServiceGallery slides ={data.gallery_images} name={data?.title} />   
                                </div>                             
                            ) : null
                        }
                    </>
                }
            </LoadingBlock>
        </div>
    );
};
export default SharkoPage;