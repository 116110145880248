const ModalPopup = ({setShow, children, addedClass}: {setShow: (boolean) => void, children: JSX.Element, addedClass?: string}): JSX.Element => {
	
	return (
		<div className={`popup ${addedClass}`} onClick={() => setShow(false)}>
			<div className="popup_inner" onClick={e => e.stopPropagation()}>
				<button className="close-btn" onClick={() => setShow(false)}>Закрыть</button>
				<div className="popup_content">
					{children}
				</div>
			</div>
		</div>
	);
  };
  
  export default ModalPopup;