import { useState } from 'react';
import { useGetCookie } from 'app/hooks';

const GdprModal = () => {
	const [isShow, setIsShow] = useState<boolean>(true);
	const notice = useGetCookie("notice");
	const handleClick = () => {
		const date = new Date();
		date.setDate(date.getDate() + 1);
		document.cookie = "notice=no; path=/; expires=" + date.toUTCString();
		setIsShow(false);
	}
	return (
		<>
			{(notice !== "no") && isShow &&
				<div className="gdpr">
					<div className="container">
						<p>На этом сайте мы используем cookie-файлы. Вы можете прочитать о cookie-файлах или изменить настройки браузера. Продолжая пользоваться сайтом без изменения настроек, вы даете согласие на использование ваших cookie-файлов. <small>Все собранные при помощи cookie-файлов данные будут храниться на территории РФ.</small></p>
						<small>Все собранные при помощи cookie-файлов данные будут храниться на территории РФ.</small>
						<form id="gdpr_form" encType="multipart/form-data" method="post">
							<input 
								type="hidden" 
								name="csrfmiddlewaretoken"
								value="6nk0ez3v5ywLCgm1RHKQVvgtOLySiTGk89j7XyhDs5OeBITLMbbyUnc0pWV3f3XU" />
							<input 
								id="id_gdpr" 
								value="57" 
								type="hidden" />
						</form>
						<button type="button" className="btn_close" title="Закрыть" onClick={handleClick}>Понятно</button>
					</div>
				</div>
			}
		</>
	)
};
export default GdprModal;