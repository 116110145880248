import { useNavigate } from 'react-router-dom';
import { useEffect } from 'react';
import HeadTags from 'shared/HeadTags';
import ym from 'react-yandex-metrika';
import { useGetManicurePageTextQuery } from 'services/api/api';
import LoadingBlock from 'shared/LoadingBlock';
import Breadcrumbs from 'shared/Breadcrumbs';
import ServiceGallery from 'shared/ServiceGallery';
import parse from 'html-react-parser';
import './manicure.style.scss';

const ManicurePage = () => {
    const navigate = useNavigate();
    const { data, isLoading, isError } = useGetManicurePageTextQuery();

    useEffect(() => {
        ym('hit', '/uslugi/manikyur-i-pedikyur');
        window.scrollTo({
            top: 0,
            behavior: "smooth"
        });
    }, [navigate]);

    return (
        <div className="manicure service-page">
            <LoadingBlock isLoading={isLoading}>
                {isError
                    ? <p>Ошибка получения данных</p>
                    : <>
                        <HeadTags
                            title={data && data.seo?.title ? data.seo.title : 'Маникюр'}
                            metaDescription={data && data.seo?.description ? data.seo.description : 'Маникюр в салоне красоты «Граф Ягода» в Брянске'}
                        />
                        <div className="container">
                            <Breadcrumbs pageName={data?.title} isService = {true}/>
                            <h1>{data?.title}</h1>
                        </div>
                        <section className="manicure_intro">
                            <div className="container">
                                <div className="manicure_intro__row row">
                                    {data?.first_block_photo && <div className="manicure_intro__img">
                                        <img src={data?.first_block_photo} alt="Маникюр" title="Маникюр" />
                                    </div>}
                                    <div className="manicure_intro__text">
                                        <div className="manicure_intro__billet billet">
                                            {data?.first_block_text && parse(data?.first_block_text)}
                                        </div>
                                        <div className="manicure_intro__list-wrap">
                                            <h2>6 причин сделать маникюр<br/> в нашем салоне</h2>
                                            <ul className="manicure_intro__list">
                                                <li>{data?.first_block_list_first_item && parse(data?.first_block_list_first_item)}</li>
                                                <li>{data?.first_block_list_second_item && parse(data?.first_block_list_second_item)}</li>
                                                <li>{data?.first_block_list_third_item && parse(data?.first_block_list_third_item)}</li>
                                                <li>{data?.first_block_list_fourth_item && parse(data?.first_block_list_fourth_item)}</li>
                                                <li>{data?.first_block_list_fifth_item && parse(data?.first_block_list_fifth_item)}</li>
                                                <li>{data?.first_block_list_sixth_item && parse(data?.first_block_list_sixth_item)}</li>
                                            </ul>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </section>
                        <section className="manicure_reasons">
                            <div className="container">
                                <p className="manicure_reasons__sub">{data?.second_block_title}</p>
                                <ul className="manicure_reasons__list">
                                    <li>
                                        <b>{data?.second_block_list_first_title}</b>
                                        {data?.second_block_list_first_text && parse(data?.second_block_list_first_text)}
                                    </li>
                                    <li>
                                        <b>{data?.second_block_list_second_title}</b>
                                        {data?.second_block_list_second_text && parse(data?.second_block_list_second_text)}
                                    </li>
                                    <li>
                                        <b>{data?.second_block_list_third_title}</b>
                                        {data?.second_block_list_third_text && parse(data?.second_block_list_third_text)}
                                    </li>
                                    <li>
                                        <b>{data?.second_block_list_fourth_title}</b>
                                        {data?.second_block_list_fourth_text && parse(data?.second_block_list_fourth_text)}
                                    </li>
                                    <li>
                                        <b>{data?.second_block_list_fifth_title}</b>
                                        {data?.second_block_list_fifth_text && parse(data?.second_block_list_fifth_text)}
                                    </li>
                                    <li>
                                        <b>{data?.second_block_list_sixth_title}</b>
                                        {data?.second_block_list_sixth_text && parse(data?.second_block_list_sixth_text)}
                                    </li>
                                    <li>
                                        <b>{data?.second_block_list_seventh_title}</b>
                                        {data?.second_block_list_seventh_text && parse(data?.second_block_list_seventh_text)}
                                    </li>
                                </ul>
                            </div>
                        </section>
                        <section className="manicure_pedicure__wrap">
                            <div className="container">
                                <h2>{data?.third_block_title}</h2>
                            </div>
                            <div className="manicure_pedicure">
                                <div className="container">
                                    <div className="manicure_pedicure__row row">
                                        <div className="manicure_pedicure__text">
                                            {data?.third_block_first_text && parse(data?.third_block_first_text)}
                                        </div>
                                        {data?.third_block_photo && <div className="manicure_pedicure__img">
                                            <img src={data?.third_block_photo} alt="Педикюр" title="Педикюр" />
                                        </div>}
                                        <div className="manicure_pedicure__billet billet">
                                            {data?.third_block_second_text && parse(data?.third_block_second_text)}
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </section>
                        {
                            data?.gallery_images.length ? ( 
                                <div className="service_gallery">
                                    <h2>Фото процесса и результат</h2>                                                                   
                                    <ServiceGallery slides ={data.gallery_images} name={data?.title} />   
                                </div>                             
                            ) : null
                        }
                    </>
                }
            </LoadingBlock>
        </div >
    );
};
export default ManicurePage;