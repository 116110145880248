import { useNavigate } from 'react-router-dom';
import { useEffect } from 'react';
import HeadTags from 'shared/HeadTags';
import ym from 'react-yandex-metrika';
import { useGetEndospherePageTextQuery } from 'services/api/api';
import LoadingBlock from 'shared/LoadingBlock';
import Breadcrumbs from 'shared/Breadcrumbs';
import ServiceGallery from 'shared/ServiceGallery';
import parse from 'html-react-parser';
import './endosphere.style.scss';

const EndospherePage = () => {
    const navigate = useNavigate();
    const { data, isLoading, isError } = useGetEndospherePageTextQuery();

    useEffect(() => {
        ym('hit', '/uslugi/endosfera');
        window.scrollTo({
            top: 0,
            behavior: "smooth"
        });
    }, [navigate]);

    return (
        <div className="endosphere service-page">
            <LoadingBlock isLoading={isLoading}>
                {isError
                    ? <p>Ошибка получения данных</p>
                    : <>
                        <HeadTags
                            title={data && data.seo?.title ? data.seo.title : 'Эндосфера в салоне Граф Ягода'}
                            metaDescription={data && data.seo?.description ? data.seo.description : 'Эндосфера - аппаратная косметическая процедура для тела и лица. Используются разные манипулы для тела и лица.'}
                        />
                        <section className="endosphere_intro">
                            <div className="container">
                                <Breadcrumbs pageName={data?.title} isService = {true}/>
                                <h1>{data?.title}</h1>
                                <div className="endosphere_intro__row row">
                                    {data?.first_block_photo && <div className="endosphere_intro__left">
                                        <img src={data?.first_block_photo} alt="Эндосфера в салоне Граф Ягода" title="Эндосфера" />
                                    </div>}
                                    <div className="endosphere_intro__right">
                                        <div className="endosphere_intro__block">
                                            {data?.first_block_first_text && parse(data?.first_block_first_text)}
                                        </div>
                                        <div className="endosphere_intro__billet billet">
                                            {data?.first_block_second_text && parse(data?.first_block_second_text)}
                                        </div>
                                        <div className="endosphere_intro__text">
                                            {data?.first_block_third_text && parse(data?.first_block_third_text)}
                                        </div>
                                    </div>

                                </div>
                            </div>
                        </section>

                        <section className="endosphere_zones">
                            <div className="container">
                                <h2>{data?.second_block_title}</h2>
                                <p className="endosphere_zones_sub">{data?.second_block_list_subtitle}</p>
                                <ul className="endosphere_zones__list">
                                    <li>{data?.second_block_list_first_item}</li>
                                    <li>{data?.second_block_list_second_item}</li>
                                    <li>{data?.second_block_list_third_item}</li>
                                    <li>{data?.second_block_list_fourth_item}</li>
                                    <li>{data?.second_block_list_fifth_item}</li>
                                </ul>
                                <div className="endosphere_zones__text">
                                    {data?.second_block_text && parse(data?.second_block_text)}
                                </div>
                            </div>
                        </section>

                        <section className="endosphere_indications">
                            <div className="container">
                                <div className="endosphere_indications__row row">
                                    <div className="endosphere_indications__text">
                                        <h2>{data?.third_block_title}</h2>
                                        <p>{data?.third_block_list_subtitle}</p>
                                    </div>
                                    <div className="endosphere_indications__billet billet">
                                        <ul className="endosphere_indications__list">
                                            <li>{data?.third_block_list_first_item}</li>
                                            <li>{data?.third_block_list_second_item}</li>
                                            <li>{data?.third_block_list_third_item}</li>
                                            <li>{data?.third_block_list_fourth_item}</li>
                                            <li>{data?.third_block_list_fifth_item}</li>
                                            <li>{data?.third_block_list_sixth_item}</li>
                                            <li>{data?.third_block_list_seventh_item}</li>
                                            <li>{data?.third_block_list_eighth_item}</li>
                                            <li>{data?.third_block_list_ninth_item}</li>
                                            <li>{data?.third_block_list_tenth_item}</li>
                                            <li>{data?.third_block_list_eleventh_item}</li>
                                        </ul>
                                    </div>
                                </div>
                            </div>
                        </section>
                        {
                            data?.gallery_images.length ? ( 
                                <div className="service_gallery">
                                    <h2>Фото процесса и результат</h2>                                                                   
                                    <ServiceGallery slides ={data.gallery_images} name={data?.title} />   
                                </div>                             
                            ) : null
                        }
                    </>
                }
            </LoadingBlock>
        </div >
    );
};
export default EndospherePage;