import { useNavigate } from 'react-router-dom';
import { useEffect, useState, useContext } from 'react';
import { ViewportContext } from 'app/viewportContext';
import HeadTags from 'shared/HeadTags';
import ym from 'react-yandex-metrika';
import Lightbox from "yet-another-react-lightbox";
import "yet-another-react-lightbox/styles.css";
import 'swiper/css';
import { YMaps, Map, Placemark } from '@pbe/react-yandex-maps';
import { Swiper, SwiperSlide } from 'swiper/react';
import { Navigation, Pagination } from 'swiper';
import Breadcrumbs from 'shared/Breadcrumbs';

const whatsapp = require("assets/img/whatsapp.svg").default;

const sliderImages = [
	{ 
		small: require('assets/img/contacts-slide_02.jpg'),
		big: require('assets/img/contacts-slide_02.jpg')
	},
];

const ContactsPage = ({openPopup}: {openPopup: () => void}) => {
    const breakpoint = 768;
	const width = useContext(ViewportContext);
    const navigate = useNavigate();
    const [galleryIndex, setGalleryIndex] = useState(-1);
	let bigImages = sliderImages.map(el => {return ({src: el.big})});
	const handleGalleryClick = (index: number) => setGalleryIndex(index);
    const [isMobile, setIsMobile] = useState(false);

    useEffect(() => {
        ym('hit', '/kontakty');
        window.scrollTo({
            top: 0,
            behavior: "smooth"
        });
    }, [navigate]);

    useEffect(() => {
		setIsMobile(width <= breakpoint);
	}, [width]);

    return (
		<div className="contacts">
			<HeadTags
				title='Контакты'
				metaDescription='Контактная информация. Адрес: г. Брянск. ул. Советская 122'
			/>
            <div className="container">
                <Breadcrumbs pageName={'Контакты'}/>
                <h1>Контакты</h1>
            </div>
            <div className="container">
                <div className="contacts_block">
                    <div className="contacts_slider">
                    <Swiper
                        className="contacts-slider"
                        slidesPerView={1}
                        modules={[Navigation, Pagination]}
                        loop={true}
                        autoplay={{
                            disableOnInteraction: false,
                        }}
                        pagination
                        navigation={{
                            nextEl: '#next',
                            prevEl: '#prev',
                        }}
                        >
                        {sliderImages.map((el, i) => {
                            return (
                            <SwiperSlide key={i}>
                                <div className="main-partners-list__item" onClick={() => handleGalleryClick(i)}>
                                    <img alt="Салон - фото" src={el.small} title="Двери" />
                                </div>
                            </SwiperSlide>
                            )
                        })}
                        </Swiper>
                        <div className="contacts-slider-prev" id="prev"></div>
                        <div className="contacts-slider-next" id="next"></div>
                    </div>
                    <div className="contacts_content">
                        <span className="contact-elem contact-elem--time">
                            <span>График работы салона:</span>
                            <span>пн-сб с 9.00 до 21.00</span>
                            <span>вс с 9.00 до 19.00</span>
                        </span>
                        <span className="contact-elem contact-elem--adr">Адрес: г.&nbsp;Брянск. ул.&nbsp;Советская&nbsp;122</span>
                        <div className="contacts_phones">
                                <a className="contact-elem contact-elem--phone" href="tel:+74832721121">Телефон: 72-11-21</a>
                                <a className="contact-elem" href="tel:+79102332035">+7 910 233-20-35</a>
                                {isMobile
                                    ? <a className="messenger whatsapp" href="whatsapp://send?phone=+79004849900"><img src={whatsapp} alt="whatsapp" width="58" /></a>
                                    : <a className="messenger whatsapp" href="https://web.whatsapp.com/send?phone=+79004849900" target="_blank" rel="noreferrer"><img src={whatsapp} alt="whatsapp" width="58" /></a>
                                }
                            </div>
                        <div className="contacts_content__btns">
                            <a className="entry-btn entry-btn--beige" href="https://n863993.yclients.com/company/741738/select-services?o=s14976815,14523269,14523307" target="_blank" rel="noreferrer">Онлайн-запись</a>
                            <button className="entry-btn entry-btn--white" onClick={() => openPopup()}>Перезвоните мне</button>
                        </div>
                    </div>
                </div>
            </div>
            <div className="contacts_map">
                <YMaps>
                    <Map
                        state={{
                            center: [53.269439, 34.3295231],
                            zoom: 16,
                        }}
                        width={'100%'}
                        height={'100%'}
                        options={{autoFitToViewport: 'always'}}
                    >
                        <Placemark
                            geometry={[53.268381,34.329941]}
                            options={{
                                preset: "islands#redIcon",
                            }}
                            modules={['geoObject.addon.balloon']}
                            properties={{
                                iconCaption: 'Советская улица, 122',
                                baloonContent: 'baloonContent',
                            }}
                        />
                    </Map>
                </YMaps>
            </div>
            <Lightbox
				slides={bigImages}
				open={galleryIndex >= 0}
				index={galleryIndex}
				close={() => setGalleryIndex(-1)}
			/>
	    </div>
	)
}
export default ContactsPage;