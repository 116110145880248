import { Link, useNavigate } from 'react-router-dom';
import { useEffect, useState } from 'react';
import HeadTags from 'shared/HeadTags';
import { Swiper, SwiperSlide } from 'swiper/react';
import { Navigation, Pagination } from 'swiper';
import ym from 'react-yandex-metrika';
import 'swiper/css';
import ModalPopup from 'shared/ModalPopup';
import ReviewsForm from './ReviewsForm';
import { useGetReviewsListQuery } from 'services/api/api';
import LoadingBlock from 'shared/LoadingBlock';
import Breadcrumbs from 'shared/Breadcrumbs';

const formatDate = (dateStr: string) => {
    const date = new Date(dateStr);
    const day = String(date.getDate()).padStart(2, '0');
    const month = String(date.getMonth() + 1).padStart(2, '0'); // Месяцы начинаются с 0 в JS
    const year = date.getFullYear();
    return (day + '.' + month + '.' + year);
};
const ReviewsPage = () => {
    const navigate = useNavigate();
    const [showForm, setShowForm] = useState(false);
    const { data, isLoading } = useGetReviewsListQuery();

    useEffect(() => {
        ym('hit', '/otzyvy');
        window.scrollTo({
            top: 0,
            behavior: "smooth"
        });
    }, [navigate]);

    return (
        <div className="reviews">
            <HeadTags
                title='Отзывы о салоне красоты "Граф Ягода"'
                metaDescription='Делитесь Вашими впечатлениями от посещения салона на нашем сайте'
            />
            <div className="container">
                <Breadcrumbs pageName={'Отзывы'}/>
                <h1>Отзывы о салоне красоты "Граф Ягода"</h1>
                <div className="reviews_block">
                    <div className="reviews_block__row">
                        <div className="reviews_block__header">Мы ценим наших клиентов, поэтому нам важно ваше мнение.</div>
                        <div className="reviews_block__text">
                            <p>Чтобы расти и развиваться, предоставлять более качественные услуги и клиентский сервис - нам необходима обратная связь от Вас.</p>
                            <p>Делитесь Вашими впечатлениями от посещения салона на нашем сайте, нажав на кнопку "Поделиться отзывом о салоне"</p>
                        </div>
                    </div>
                    <div className="reviews_block__btns">
                        <button className="reviews_block__btn review-btn" onClick={() => setShowForm(true)}><span>Поделиться отзывом о салоне</span></button>
                    </div>
                </div>
                <LoadingBlock isLoading={isLoading}>
                    <div className="reviews_slider">
                        {data
                            ? data.length
                                ? <>
                                <Swiper
                                    className="reviews-slider"
                                    modules={[Navigation, Pagination]}
                                    spaceBetween={10}
                                    slidesPerView={1}
                                    pagination
                                    navigation={{
                                        nextEl: '#reviews-next',
                                        prevEl: '#reviews-prev',
                                    }}
                                    breakpoints={{
                                        480: {
                                            slidesPerView: 1,
                                            spaceBetween: 10
                                        },
                                        768: {
                                            slidesPerView: 2,
                                            spaceBetween: 10
                                        },
                                        1140: {
                                            slidesPerView: 3,
                                            spaceBetween: 18
                                        },
                                        1400: {
                                            slidesPerView: 3,
                                            spaceBetween: 28
                                        }
                                    }}
                                    loop>
                                    {data.map((el, i) =>
                                        <SwiperSlide key={`about${i}`}>
                                            <div className="review">
                                                <span className="review_name">{el.name}</span>
                                                <time className="review_time" dateTime={el.created_at}>{formatDate(el.created_at)}</time>
                                                <p className="review_text">{el.comment}</p>
                                            </div>
                                        </SwiperSlide>
                                    )}</Swiper>
                                    <div className="slider-prev" id="reviews-prev"></div>
                                    <div className="slider-next" id="reviews-next"></div>
                                    </>
                                : null
                            : <p>Загрузка ...</p>}

                    </div>
                </LoadingBlock>
            </div>
            {showForm && <ModalPopup setShow={setShowForm} addedClass="review-popup">
                <ReviewsForm setShow={setShowForm} />
            </ModalPopup>}
        </div>
    )
}
export default ReviewsPage;