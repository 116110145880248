import { Link } from 'react-router-dom';
import Menu from 'features/Menu/Menu';
import { useContext, useState, useEffect } from 'react'
import { ViewportContext } from 'app/viewportContext';
const logoImg = require("assets/img/logo.svg").default;
const telegram = require("assets/img/telegram.svg").default;
const vk = require("assets/img/vk.svg").default;
const whatsapp = require("assets/img/whatsapp.svg").default;

const Footer = () => {
  const breakpoint = 768;
	const width = useContext(ViewportContext);
  const [isMobile, setIsMobile] = useState(false);

  useEffect(() => {
		setIsMobile(width <= breakpoint);
	}, [width]);

  return (
    <footer>
      <div className="container footer" itemScope itemType="http://schema.org/WPFooter">        
        <Link to="/" className="footer_logo">
          <img src={logoImg} alt="Логотип компании" title="Граф ягода" width="128"/>
        </Link>
        <div className="footer_menu">
          <Menu/>
        </div>
        <a className="entry-btn" href="https://n863993.yclients.com/company/741738/select-services?o=s14976815,14523269,14523307" target="_blank" rel="noreferrer">Записаться в&nbsp;салон</a>
        <div className="footer_contacts">
          <div className="slogan footer_slogan">салон красоты</div>
          <a className="contact-elem contact-elem--phone" href="tel:+74832721121">72-11-21</a>
          <Link className="contact-elem contact-elem--adr" to="/kontakty#map">г.&nbsp;Брянск, ул.&nbsp;Советская, д.&nbsp;122</Link>
          <div className="footer_socials">
            <a className="messenger telegram" href="https://t.me/graf_yagoda_32" target="_blank" rel="noreferrer"><img src={telegram} alt="Telegram" width="26"/></a>
            <a className="messenger vk" href="https://vk.com/grafyagoda" target="_blank" rel="noreferrer"><img src={vk} alt="VK" width="30"/></a>
            {isMobile
							? <a className="messenger whatsapp" href="whatsapp://send?phone=+79004849900"><img src={whatsapp} alt="whatsapp" width="58" /></a>
							: <a className="messenger whatsapp" href="https://web.whatsapp.com/send?phone=+79004849900" target="_blank" rel="noreferrer"><img src={whatsapp} alt="whatsapp" width="58" /></a>
						}
          </div>
        </div>
        <div className="copy">
          <span>© Copyright — Все права защищены. </span>
          <span>{new Date().getFullYear()} ГРАф ЯГОДА</span>
          <span className="footer_creator">Создание сайта <a href="https://sait-region.ru/" target="_blank">ООО "Регион центр"</a></span>
        </div>
      </div>      
    </footer>
  )
}
export default Footer;