import { NavLink } from "react-router-dom";

const Breadcrumbs = ({pageName, isService = false}) => {
	
	return (		
        <ul className="breadcrumbs" itemScope itemType="http://schema.org/BreadcrumbList">
            <li itemProp="itemListElement" itemScope itemType="http://schema.org/ListItem">
                <NavLink itemProp="item" 
					to={'/'}
					title={'Главная'}
				>{"Главная"}</NavLink>
            </li>
            { isService ? 
                <li itemProp="itemListElement" itemScope itemType="http://schema.org/ListItem">
                    <NavLink itemProp="item" 
                        to={'/uslugi'}
                        title={'Наши услуги'}
                    >{'Наши услуги'}</NavLink>
                </li> : null
            }
            <li itemProp="itemListElement" itemScope itemType="http://schema.org/ListItem">
                <span>{pageName}</span>
            </li>
        </ul>	
	)
}
export default Breadcrumbs;