import { Link, useNavigate } from 'react-router-dom';
import { useEffect } from 'react';
import HeadTags from 'shared/HeadTags';
import ym from 'react-yandex-metrika';
import { useGetCosmetologyPageTextQuery } from 'services/api/api';
import LoadingBlock from 'shared/LoadingBlock';
import Breadcrumbs from 'shared/Breadcrumbs';
import ServiceGallery from 'shared/ServiceGallery';
import parse from 'html-react-parser';
import './cosmetology.style.scss';
import { title } from 'process';


const CosmetologyPage = () => {
    const navigate = useNavigate();
    const { data, isLoading, isError } = useGetCosmetologyPageTextQuery();

    useEffect(() => {
        ym('hit', '/uslugi/kosmetologiya');
        window.scrollTo({
            top: 0,
            behavior: "smooth"
        });
    }, [navigate]);

    return (
        <div className="cosmetology service-page">
            <LoadingBlock isLoading={isLoading}>
                {isError
                    ? <p>Ошибка получения данных</p>
                    : <>
                        <HeadTags
                            title={data && data.seo?.title ? data.seo.title : 'Коллариум с витамином Д и выработкой коллагена'}
                            metaDescription={data && data.seo?.description ? data.seo.description : 'Коллариум с витамином Д и выработкой коллагена'}
                        />
                        <div className="container">
                            <Breadcrumbs pageName={data?.title} isService = {true}/>
                            <h1>{data?.title}</h1>
                        </div>
                        <section className="cosmetology_intro">
                            <div className="container">
                                <div className="cosmetology_intro__row row">
                                    {data?.first_block_photo && <div className="cosmetology_intro__img">
                                        <img src={data?.first_block_photo} alt="Косметология" title="Косметология"/>
                                    </div>}
                                    <div className="cosmetology_intro__text">
                                        {data?.first_block_text && parse(data?.first_block_text)}
                                        <div className="cosmetology_intro__billet billet">
                                            <ul className="cosmetology_intro__list">
                                                <li>{data?.first_block_list_first_item}</li>
                                                <li>{data?.first_block_list_second_item}</li>
                                                <li>{data?.first_block_list_third_item}</li>
                                                <li>{data?.first_block_list_fourth_item}</li>
                                                <li>{data?.first_block_list_fifth_item}</li>
                                                <li>{data?.first_block_list_sixth_item}</li>
                                                <li>{data?.first_block_list_seventh_item}</li>
                                            </ul>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </section>
                        <section className="cosmetology_lifting">
                            <div className="container">
                                <div className="cosmetology_lifting__row row">
                                    <div className="cosmetology_lifting__text">
                                        <p className="paragraph-decor">{data?.second_block_text && parse(data?.second_block_text)}</p>
                                        <ul className="cosmetology_lifting__list">
                                            <li>{data?.second_block_list_first_item}</li>
                                            <li>{data?.second_block_list_second_item}</li>
                                            <li>{data?.second_block_list_third_item}</li>
                                            <li>{data?.second_block_list_fourth_item}</li>
                                            <li>{data?.second_block_list_fifth_item}</li>
                                            <li>{data?.second_block_list_sixth_item}</li>
                                        </ul>
                                    </div>
                                    {data?.second_block_photo && <div className="cosmetology_lifting__img">
                                        <img src={data?.second_block_photo} alt="RF-ЛИФТИНГ" title="RF-ЛИФТИНГ"/>
                                    </div>}
                                </div>
                            </div>
                        </section>
                        <section className="cosmetology_injection__wrap">
                            <div className="container">
                                <h2>{data?.third_block_title}</h2>
                            </div>
                            <div className="cosmetology_injection">
                                <div className="container">
                                    <div className="cosmetology_injection__row row">
                                        <div className="cosmetology_injection__text">
                                            <div className="cosmetology_injection__billet billet">
                                                <p className="paragraph-decor">{data?.third_block_first_text && parse(data?.third_block_first_text)}</p>
                                            </div>
                                            {data?.third_block_second_text && parse(data?.third_block_second_text)}
                                        </div>
                                        {data?.third_block_photo && <div className="cosmetology_injection__img">
                                            <img src={data?.third_block_photo} alt="Инъекционная косметология " title="Инъекционная косметология "/>
                                        </div>}
                                    </div>
                                </div>
                            </div>
                        </section>
                        <section className="cosmetology_botox">
                            <div className="container">
                                <h2>{data?.fourth_block_title}</h2>
                                <ul className="cosmetology_botox__list">
                                    <li>
                                        {data?.fourth_block_first_photo && <img src={data?.fourth_block_first_photo} alt="Разглаживание морщин" title="Разглаживание морщин"/>}
                                        <div className="paragraph-decor">{data?.fourth_block_first_text && parse(data?.fourth_block_first_text)}</div>
                                    </li>
                                    <li>
                                        {data?.fourth_block_second_photo && <img src={data?.fourth_block_second_photo} alt="Коррекции бровей и уголков губ" title="Коррекции бровей и уголков губ"/>}
                                        <div className="paragraph-decor">{data?.fourth_block_second_text && parse(data?.fourth_block_second_text)}</div>
                                    </li>
                                    <li>
                                        {data?.fourth_block_third_photo && <img src={data?.fourth_block_third_photo} alt="Устранения «гусиных лапок»" title="Устранения «гусиных лапок»"/>}
                                        <div className="paragraph-decor">{data?.fourth_block_third_text && parse(data?.fourth_block_third_text)}</div>
                                    </li>
                                    <li>
                                        {data?.fourth_block_fourth_photo && <img src={data?.fourth_block_fourth_photo} alt="Коррекции линии челюсти" title="Коррекции линии челюсти"/>}
                                        <div className="paragraph-decor">{data?.fourth_block_fourth_text && parse(data?.fourth_block_fourth_text)}</div>
                                    </li>
                                </ul>
                            </div>
                        </section>
                        <section className="cosmetology_outro">
                            <div className="container">
                                <div className="cosmetology_outro__row row">
                                    {data?.fifth_block_photo && <div className="cosmetology_outro__img">
                                        <img src={data?.fifth_block_photo} alt="Процедура биоревитализация" title="Процедура биоревитализация"/>
                                    </div>}
                                    <div className="cosmetology_outro__text">
                                        <div className="cosmetology_outro__billet billet">
                                            <p className="paragraph-decor">{data?.fifth_block_first_text && parse(data?.fifth_block_first_text)}</p>
                                        </div>
                                        {data?.fifth_block_second_text && parse(data?.fifth_block_second_text)}
                                    </div>
                                </div>
                            </div>
                        </section>
                        {
                            data?.gallery_images.length ? ( 
                                <div className="service_gallery">
                                    <h2>Наша фотогалерея</h2>                                                                   
                                    <ServiceGallery slides ={data.gallery_images} name={data?.title} />   
                                </div>                             
                            ) : null
                        }
                    </>
                }

            </LoadingBlock>
        </div >
    );
};
export default CosmetologyPage;