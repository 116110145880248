import { useNavigate } from 'react-router-dom';
import { useEffect } from 'react';
import HeadTags from 'shared/HeadTags';
import ym from 'react-yandex-metrika';
import { useGetJapanMassagePageTextQuery } from 'services/api/api';
import LoadingBlock from 'shared/LoadingBlock';
import Breadcrumbs from 'shared/Breadcrumbs';
import ServiceGallery from 'shared/ServiceGallery';
import parse from 'html-react-parser';
import './japanmassage.style.scss';

const JapanMassagePage = () => {
    const navigate = useNavigate();
    const { data, isLoading, isError } = useGetJapanMassagePageTextQuery();

    useEffect(() => {
        ym('hit', '/uslugi/yaponskyi-massag-dly-volos-i-golovy');
        window.scrollTo({
            top: 0,
            behavior: "smooth"
        });
    }, [navigate]);

    return (
        <div className="kobido service-page">
            <LoadingBlock isLoading={isLoading}>
                {isError
                    ? <p>Ошибка получения данных</p>
                    : <>
                        <HeadTags
                            title={data && data.seo?.title ? data.seo.title : 'Массаж'}
                            metaDescription={data && data.seo?.description ? data.seo.description : 'Массаж'}
                        />
                        <section className="kobido_intro">
                            <div className="container">
                                <Breadcrumbs pageName={data?.title} isService = {true}/>
                                <h1>{data?.title}</h1>
                                <div className="kobido_intro__row row">
                                    {data?.first_block_photo && <div className="kobido_intro__img">
                                        <img src={data?.first_block_photo} alt="Японский массаж в салоне Граф Ягода" title="Японский массаж Кобидо"/>
                                    </div>}
                                    <div className="kobido_intro__text">
                                        <div className="kobido_intro__block">
                                            {data?.first_block_first_text && parse(data?.first_block_first_text)}
                                        </div>
                                        <div className="kobido_intro__billet billet">
                                            <h2>{data?.first_block_title}</h2>
                                            {data?.first_block_second_text && parse(data?.first_block_second_text)}
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </section>

                        <section className="kobido_prepare">
                            <div className="container">
                                <div className="kobido_prepare__row row">
                                    <div className="kobido_prepare__text">
                                        <h2>{data?.second_block_title}</h2>
                                        <p className="paragraph-decor">{data?.second_block_first_paragraph}</p>
                                        <ul className="kobido_prepare__list">
                                            <li>{data?.second_block_list_first_item}</li>
                                            <li>{data?.second_block_list_second_item}</li>
                                            <li>{data?.second_block_list_third_item}</li>
                                        </ul>
                                        <p>{data?.second_block_second_paragraph}</p>
                                    </div>
                                    {data?.second_block_photo && <div className="kobido_prepare__img">
                                        <img src={data?.second_block_photo} alt="Подготовка к массажу Кобидо" title="Подготовка к массажу Кобидо"/>
                                    </div>}
                                </div>
                            </div>
                        </section>

                        <section className="kobido_precautions">
                            <div className="container">
                                <h2>{data?.third_block_title}</h2>
                            </div>
                            <div className="container kobido_precautions__container">
                                <div className="kobido_precautions__row row">
                                    {data?.third_block_photo && <div className="kobido_precautions__img">
                                        <img src={data?.third_block_photo} alt="Меры предосторожности" title="Меры предосторожности"/>
                                    </div>}
                                    <div className="kobido_precautions__text">
                                        <div className="kobido_precautions__billet billet">
                                            {data?.third_block_first_text && parse(data?.third_block_first_text)}
                                        </div>
                                        <div className="kobido_precautions__block">
                                            {data?.third_block_list_subtitle && parse(data?.third_block_list_subtitle)}
                                            <ul className="kobido_precautions__list">
                                                <li>{data?.third_block_list_first_item && parse(data?.third_block_list_first_item)}</li>
                                                <li>{data?.third_block_list_second_item && parse(data?.third_block_list_second_item)}</li>
                                            </ul>
                                        </div>
                                        <div className="kobido_precautions__billet billet">
                                            {data?.third_block_second_text && parse(data?.third_block_second_text)}
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </section>

                        <section className="kobido_effects">
                            <div className="container">
                                <h2>{data?.fourth_block_title}</h2>
                                <div className="kobido_effects_sub">
                                    {data?.fourth_block_first_text && parse(data?.fourth_block_first_text)}
                                </div>
                                <div className="kobido_effects_sub">
                                    {data?.fourth_block_list_subtitle && parse(data?.fourth_block_list_subtitle)}
                                </div>
                                <ul className="kobido_effects__list">
                                    <li>{data?.fourth_block_list_first_item}</li>
                                    <li>{data?.fourth_block_list_second_item}</li>
                                    <li>{data?.fourth_block_list_third_item}</li>
                                    <li>{data?.fourth_block_list_fourth_item}</li>
                                    <li>{data?.fourth_block_list_fifth_item}</li>
                                    <li>{data?.fourth_block_list_sixth_item}</li>
                                </ul>
                            </div>
                        </section>

                        <section className="kobido_contrains">
                            <div className="container">
                                <div className="kobido_contrains__row row">
                                    <div className="kobido_contrains__header">
                                        <h2>{data?.fifth_block_title}</h2>
                                    </div>
                                    <div className="kobido_contrains__text">
                                        {data?.fifth_block_text && parse(data?.fifth_block_text)}
                                    </div>
                                    <div className="kobido_contrains__billet billet">
                                        <ul className="kobido_contrains__list">
                                            <ul>
                                                <li>{data?.fifth_block_list_first_item}</li>
                                                <li>{data?.fifth_block_list_second_item}</li>
                                            </ul>
                                            <ul>
                                                <li>{data?.fifth_block_list_third_item}</li>
                                                <li>{data?.fifth_block_list_fourth_item}</li>
                                            </ul>
                                            <ul>
                                                <li>{data?.fifth_block_list_fifth_item}</li>
                                                <li>{data?.fifth_block_list_sixth_item}</li>
                                            </ul>
                                        </ul>
                                    </div>
                                </div>
                            </div>
                        </section>
                        {
                            data?.gallery_images.length ? ( 
                                <div className="service_gallery">
                                    <h2>Фото процесса и результат</h2>                                                                   
                                    <ServiceGallery slides ={data.gallery_images} name={data?.title} />   
                                </div>                             
                            ) : null
                        }
                    </>
                }
            </LoadingBlock>
        </div >
    );
};
export default JapanMassagePage;