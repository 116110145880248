import { Link, useNavigate } from 'react-router-dom';
import { useEffect } from 'react';
import HeadTags from 'shared/HeadTags';
import ym from 'react-yandex-metrika';
import { useGetCollariumPageTextQuery } from 'services/api/api';
import LoadingBlock from 'shared/LoadingBlock';
import Breadcrumbs from 'shared/Breadcrumbs';
import ServiceGallery from 'shared/ServiceGallery';
import parse from 'html-react-parser';
import './collarium.style.scss';

const CollariumPage = () => {
    const navigate = useNavigate();
    const { data, isLoading, isError } = useGetCollariumPageTextQuery();

    useEffect(() => {
        ym('hit', '/uslugi/solyaryi-s-vitaminom-d');
        window.scrollTo({
            top: 0,
            behavior: "smooth"
        });
    }, [navigate]);

    return (
        <div className="collarium">
            <LoadingBlock isLoading={isLoading}>
                {isError
                    ? <p>Ошибка получения данных</p>
                    : <>
                        <HeadTags
                            title={data && data.seo?.title ? data.seo.title : 'Коллариум с витамином Д и выработкой коллагена'}
                            metaDescription={data && data.seo?.description ? data.seo.description : 'Коллариум с витамином Д и выработкой коллагена'}
                        />
                        <div className="container">
                            <Breadcrumbs pageName={data?.title} isService = {true}/>
                            <h1>{data?.title}</h1>
                        </div>
                        <section className="collarium_intro">
                            <div className="container">
                                <div className="collarium_intro__row row">
                                    {data?.first_block_photo && <div className="collarium_intro__img">
                                        <img src={data?.first_block_photo} alt="Коллариум - фото" title="Коллариум" />
                                    </div>}
                                    <div className="collarium_intro__text">
                                        <p>{data?.first_block_first_text && parse(data?.first_block_first_text)}</p>
                                        <div className="collarium_intro__billet">
                                            <p className="paragraph-decor">{data?.first_block_second_text && parse(data?.first_block_second_text)}</p>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </section>
                        <section className="collarium_difference">
                            <div className="container collarium_difference__row row">
                                <div className="collarium_difference__text">
                                    <h2>{data?.second_block_title}</h2>
                                    <p className="paragraph-decor">{data?.second_block_text && parse(data?.second_block_text)}</p>
                                </div>
                                {data?.second_block_photo && <div className="collarium_difference__img">
                                    <img src={data?.second_block_photo} alt="Коллариум - отличие от солярия" title="Коллариум" />
                                </div>}
                            </div>
                        </section>
                        <section className="collarium_effect">
                            <div className="container">
                                <h2>{data?.third_block_main_title}</h2>
                                <p className="collarium_effect__sub">{data?.third_block_text_under_main_title && parse(data?.third_block_text_under_main_title)}</p>
                                <ul className="collarium_effect__list">
                                    <li>
                                        {data?.third_block_first_photo && <img src={data?.third_block_first_photo} alt="Омоложение" title="Омоложение" />}
                                        <h3 className="collarium_effect__name">{data?.third_block_first_title}</h3>
                                        <p className="paragraph-decor">{data?.third_block_first_text && parse(data?.third_block_first_text)}</p>
                                    </li>
                                    <li>
                                        {data?.third_block_second_photo && <img src={data?.third_block_second_photo} alt="Регенерация кожи" title="Регенерация кожи" />}
                                        <h3 className="collarium_effect__name">{data?.third_block_second_title}</h3>
                                        <p className="paragraph-decor">{data?.third_block_second_text && parse(data?.third_block_second_text)}</p>
                                    </li>
                                    <li>
                                        {data?.third_block_third_photo && <img src={data?.third_block_third_photo} alt="Разглаживающий" title="Разглаживающий" />}
                                        <h3 className="collarium_effect__name">{data?.third_block_third_title}</h3>
                                        <p className="paragraph-decor">{data?.third_block_third_text && parse(data?.third_block_third_text)}</p>
                                    </li>
                                </ul>
                            </div>
                        </section>
                        <section className="collarium_usage">
                            <div className="container">
                                <h2>{data?.fourth_block_title}</h2>
                                <div className="collarium_usage__row row">
                                    {data?.fourth_block_photo && <div className="collarium_usage__img">
                                        <img src={data?.fourth_block_photo} alt="Услуга Коллариум" title="Услуга Коллариум" />
                                    </div>}
                                    <div className="collarium_usage__text">
                                        <p className="paragraph-decor">{data?.fourth_block_text && parse(data?.fourth_block_text)}</p>
                                    </div>
                                </div>
                            </div>
                        </section>
                        {
                            data?.gallery_images.length ? ( 
                                <div className="service_gallery">
                                    <h2>Фото процесса и результат</h2>                                                                   
                                    <ServiceGallery slides ={data.gallery_images} name={data?.title} />   
                                </div>                             
                            ) : null
                        }
                    </>
                }
            </LoadingBlock>
        </div >
    );
};
export default CollariumPage;